import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface SettingState {
    lang: string,
    currency: string,
    pathHome: string,
    selection: string | null,
    objectId: string | undefined

}

const initialState: SettingState = {
    lang: 'RU',
    currency: 'USD', 
    pathHome: '',
    selection: null,
    objectId: undefined
}

export const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        setLang: (state, action: PayloadAction<string>) => {
            state.lang = action.payload;
        },
        setCurrency: (state, action: PayloadAction<string>) => {
            state.currency = action.payload;
        },
        setPath: (state, action: PayloadAction<string>) => {
            state.pathHome = action.payload;
        },
        setSelection: (state, action: PayloadAction<string | null>) => {
            state.selection = action.payload;
        },
        setObjectId: (state, action: PayloadAction<string>) => {
            state.objectId = action.payload;
        },
    }
})

export const { setLang, setCurrency, setPath, setSelection, setObjectId } = settingSlice.actions;

export const selectSettingState = (state: RootState) => state.setting;

export default settingSlice.reducer;