import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {PropertyProposal, PropertyType, PropertyTypeWithGeo} from "../types";

interface ObjectsI {
    objects: PropertyType[],
    objectsGeo: PropertyTypeWithGeo[],
    proposal: PropertyProposal[]
}

const initialState: ObjectsI = {
    objects: [],
    objectsGeo: [],
    proposal: []
}

export const objectSlice = createSlice({
    name: 'objects',
    initialState,
    reducers: {
        setProposal: (state, action: PayloadAction<PropertyProposal>) => {
            state.proposal[0] = action.payload;
        },
        setObject: (state, action: PayloadAction<PropertyType[]>) => {
            state.objects = action.payload;
        },
        setGeoToObj: (state, action: PayloadAction<PropertyType[]>) => {
            state.objectsGeo = action.payload.map((item) => {
                if (item.values['55'].value.length) {

                    let coordinates: [number, number];

                    coordinates = item.values['55'].value[0].recordValues['45'].split(',').map((geo: string) => Number(geo))
                    if (isNaN(coordinates[0])) {
                        coordinates =  item.values['55'].value[0].recordValues['45'].split('(')[1].split(')')[0].split(',').map((geo: string) => Number(geo));
                    }
                    const isNumberCoord = typeof coordinates[0] === 'number' && typeof coordinates[1] === 'number';
                    return {
                        id: item.id,
                        values: item.values,
                        geo: isNumberCoord ? coordinates : [0, 0]
                    }
                }
                return {
                    id: item.id,
                    values: item.values,
                    geo: [0, 0]
                }
            });
        }
    }
});

export const {setObject, setGeoToObj, setProposal} = objectSlice.actions;

export const selectObjectState = (state: RootState) => state.setting;

export default objectSlice.reducer;