import React, {useState} from "react";
import {useAppDispatch} from "../../app/hooks";
// import FilterBlock from "./FilterBlock";
import ContentContainer from '../main/teasers/ContentContainer';

const SearchInput: React.FC = () => {
    const dispatch = useAppDispatch();
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    function handleClick() {
        setOpenFilter(!openFilter)
    }

    return (
        <div className="search-block">
            {/*<input className="search-input" placeholder="Поиск"/>*/}
            {/*<button className="search-input-button">Найти</button>*/}
            {/*<button className="search-input-filter" onClick={handleClick}>Фильтр</button>*/}
            {/*{openFilter && <FilterBlock/>}*/}
            {!openFilter && <ContentContainer/>}
        </div>

    )
}

export default SearchInput;